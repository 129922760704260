.main-container {
    width: 100%;
    overflow-y: auto;
    height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    &__header {
        height: 75px;
        max-height: 75px;
        border-bottom: 1px solid #e0e0e0;
        justify-content: center;
    }

    &__content {
        align-content: center;
    }
}