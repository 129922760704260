.root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 24px;

    &.MuiGrid-root {
        width: auto;
        height: auto;
    }
}

.close-modal {
    & .close-icon-button {
        margin-top: -35px;
        margin-right: -20px;

        &.MuiIconButton-root:hover {
            background-color: unset;
        }

        & .icon {
            font-size: 24px;
        }
    }
}