.react-datepicker-wrapper {
    width: 100%;
}

.date-range-picker {
    width: 100%;
    box-sizing: border-box;
    height: 55px;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    font-size: 16px;
    color: #7b7b7b;
    padding-left: 12px;
}

.react-datepicker-popper{
    z-index: 1000;
}